.traffictable-editing .editable-cells-table td.p-cell-editing {
  padding-top: 0;
  padding-bottom: 0;
}




.p-inputtextarea {
  padding-top: 2px;
  padding-bottom: 2px;

}