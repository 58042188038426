

.p-togglebutton.p-button.p-highlight:hover {
    background: var(--purple-500) !important;
    border-color: var(--purple-500) !important;
    color: #ffffff;
}

.p-togglebutton.p-button.p-highlight {
    background: var(--purple-500) !important;
    border-color: var(--purple-500) !important;
    color: blue;
}

