.admin-list td {
    vertical-align: middle;
}

.admin-list th {
    vertical-align: middle;
}

.admin-list .p-column-header-content {
    display: block;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.editable-cells-table td.p-cell-editing {
    padding-top: 0;
    padding-bottom: 0;
}

.loadingSpinner {
    display: block;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.image-order-list .product-item {
    display: flex;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    padding: 0.5rem;
    width: 100%;
}

.image-order-list .p-button-icon-only {
    margin: auto;
    display: inline-block !important;
}

.price-group-list .p-button-icon-only {
    margin: auto;
    display: inline-block !important;
}

.image-order-list .product-item img {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
    margin-right: 1rem;
    margin-left: 1rem;
    vertical-align: middle;
}

.image-order-list .product-item .product-order-no {
    align-items: flex-start;
}

.image-order-list .product-item .product-action {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

@media screen and (max-width: 576px) {
    .image-order-list .product-item {
        flex-wrap: wrap;
    }

    .image-order-list .product-item .image-container {
        width: 100%;
        text-align: center;
    }

    .image-order-list .product-item img {
        margin: 0 0 1rem 0;
        vertical-align: middle;
        width: 100px;
    }

    .image-order-list .product-item .product-action {
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
    }
}

.price-group-list .editable-cells-table td.p-cell-editing {
    padding-top: 0;
    padding-bottom: 0;
}

.admin-list .price-group-list {
    padding-top: 0;
    padding-bottom: 0;
}
